<script setup>

</script>

<template>
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M9.74531 11.4547C7.38956 13.3416 3.94116 13.193 1.75736 11.0092C-0.585786 8.6661 -0.585786 4.86711 1.75736 2.52396C4.10051 0.180815 7.89949 0.180815 10.2426 2.52396C12.4265 4.70777 12.575 8.15615 10.6882 10.512L15.2709 15.0947C15.5313 15.3551 15.5313 15.7772 15.2709 16.0376C15.0106 16.2979 14.5885 16.2979 14.3282 16.0376L9.74531 11.4547ZM2.70017 10.0665C0.877724 8.24399 0.877724 5.28922 2.70017 3.46677C4.52261 1.64433 7.47739 1.64433 9.29979 3.46677C11.1209 5.28788 11.1223 8.23965 9.30388 10.0625C9.30246 10.0638 9.30113 10.065 9.29979 10.0665C9.29846 10.0678 9.29713 10.0691 9.2958 10.0705C7.47306 11.8889 4.52128 11.8875 2.70017 10.0665Z"
              fill="#131933"/>
    </svg>
</template>

<style scoped>

</style>
